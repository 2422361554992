<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-29.mp3"),
      },
      timeStampList: [8.1, 12.8, 25.9, 30.3, 35.8],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ zài nǎr? Wǒ zài zhèr!",
            chineseWords: "你在哪儿？我在这儿！",
          },
          {
            id: 2,
            spelling: "Tā zài nǎr? Tā zài zhèr.",
            chineseWords: "他在哪儿？他在这儿。",
          },
          {
            id: 3,
            spelling: "Gǒu zài nǎr? Gǒu zài nǎr?",
            chineseWords: "狗在哪儿？狗在哪儿？",
          },
          {
            id: 4,
            spelling: "Gǒu zài nàr! Gǒu zài nàr!",
            chineseWords: "狗在那儿！狗在那儿！",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
